<template>
	<div>
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="配置文件名">
				<Input placeholder="请输入配置文件名" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button @click="uploadTemplateShow">上传文档配置模板</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
		></CtmsDataGrid>
		<ConfigView
			:visible="viewVisible"
			:viewId="viewId"
			:viewName="viewName"
			@onCancel="viewVisible = false"
		></ConfigView>
		<UploadTemplate
			:visible.sync="uploadVisible"
			@on-success="handleUploadSuccess"
		></UploadTemplate>
	</div>
</template>

<script>
import { mapState } from "vuex"
import api from "@/api/setting/docConfig"
import View from "./View.vue"
import UploadTemplate from "./UploadTemplate.vue"

const { getConfigList, deleteDocConfig } = api

export default {
	name: "docConfig",
	data() {
		return {
			loading: false,
			columns: [
				{
					title: "文件名",
					minWidth: 150,
					key: "name",
					align: "center"
				},
				{
					title: "上传时间",
					minWidth: 150,
					key: "createdTime",
					align: "center"
				},
				{
					title: "版本号",
					key: "version",
					minWidth: 100,
					align: "center"
				},
				{
					title: "操作",
					key: "action",
					width: 180,
					fixed: "right",
					renderButton: params => {
						const btnList = [
							{
								label: "编辑文档目录",
								on: {
									click: this.handleShow
								}
							},
							{
								label: "下载",
								on: {
									click: () => {
										window.open(
											`${this.$baseUrl}/common/doc-config/${params.row.id}/download?token=${this.$store.state.user.token}`
										)
									}
								}
							},
							{
								label: "删除",
								confirm: {
									title: "您确认删除此模板吗？"
								},
								on: {
									click: this.handleDelete
								}
							}
						]
						if (params.row.id === 1) {
							btnList[2] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			listData: [],
			lists: [],
			searchParams: {
				name: ""
			},
			viewId: "",
			viewName: "",
			viewVisible: false,
			uploadVisible: false
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 100
		})
		// ...mapState('permission', ['systemActionPermissions']),
	},
	methods: {
		async initList() {
			this.loading = true
			const res = await getConfigList()
			if (res) {
				this.lists = res.data
				this.searchHandle()
			}
			this.loading = false
		},
		handleShow({ row }) {
			this.viewId = row.id
			this.viewName = row.name
			this.viewVisible = true
		},
		// 删除
		async handleDelete({ row }) {
			this.loading = true
			const ret = await deleteDocConfig({ id: row.id })
			if (ret) {
				this.$Message.success("操作成功！")
				this.initList()
			}
			this.loading = false
		},
		// 重置
		reset() {
			this.searchParams = {
				name: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			const { name } = this.searchParams
			if (!name) {
				this.listData = this.lists
				return
			}
			this.listData = this.lists.filter(item =>
				item.name.includes(this.searchParams.name)
			)
		},

		uploadTemplateShow() {
			this.uploadVisible = true
		},
		handleUploadSuccess() {
			this.uploadVisible = false
			this.initList()
		}
	},
	created() {
		this.initList()
	},
	components: {
		ConfigView: View,
		UploadTemplate
	}
}
</script>

<style scoped></style>
