<template>
	<Modal
		class="upload-template-wrapper"
		:value="visible"
		:mask-closable="false"
		:scrollable="true"
		@on-cancel="handleCancel"
	>
		<Spin v-if="loading" fix></Spin>
		<Form
			:model="formData"
			ref="uploadForm"
			label-position="top"
			:rules="rules"
		>
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="版本号" prop="version">
						<Input v-model="formData.version" placeholder="请输入版本号" />
					</FormItem>
				</Col>
				<!--     <Col span="24">
          <FormItem label="模板类型" prop="type">
            <Select v-model="formData.type" >
              <Option :value="1">流程文档模板</Option>
              <Option :value="0">遗传办文档模板</Option>
            </Select>
          </FormItem>
        </Col>-->
			</Row>
			<Row :gutter="32">
				<Col span="24">
					<Upload
						action="/"
						:before-upload="beforeUpload"
						style="display: inline-block"
					>
						<Button>上传模板</Button>
					</Upload>
					<div v-if="file">{{ file.name }}</div>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button @click="handleCancel">关闭</Button>
			<Button type="primary" :loading="loading" @click="handleSubmit"
				>提交</Button
			>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/docConfig"

const { uploadDocConfig } = api
export default {
	name: "UploadTemplate",
	props: ["visible"],
	data() {
		return {
			file: null,
			loading: false,
			formData: {},
			rules: {
				version: {
					required: true,
					message: "版本号不能为空",
					trigger: "blur change"
				},
				type: {
					required: true,
					type: "number",
					message: "请选择模板类型",
					trigger: "blur change"
				}
			}
		}
	},
	watch: {
		visible(val) {
			if (val) {
				this.$refs.uploadForm.resetFields()
				this.formData = {}
				this.file = null
			}
		}
	},
	methods: {
		handleCancel() {
			this.$emit("update:visible", false)
		},
		async handleSubmit() {
			const valid = await this.$refs.uploadForm.validate()
			if (valid) {
				if (!this.file) {
					this.$Message.error("请上传模板!")
					return false
				}
				const fd = new FormData()
				fd.append("file", this.file)
				fd.append("name", this.file.name)
				fd.append("version", this.formData.version)
				fd.append("type", 1)
				this.loading = true
				const res = await uploadDocConfig(fd)
				if (res) {
					this.$emit("on-success")
				}
				this.loading = false
			}
			return false
		},
		beforeUpload(file) {
			this.file = file
			return false
		}
	}
}
</script>

<style lang="less"></style>
