<template>
	<span
		ref="docTreeItem"
		class="custom-tree-item"
		@mouseover.stop="handleMouseOver(data)"
		@mouseleave.stop="hoverId = ''"
		@dragstart.stop="handleDragStart"
		@dragover.stop="handleDragOver"
		@dragend.stop="handleDragEnd"
		@drop.stop="handleDrop"
		:draggable="draggable"
	>
		<Icon type="ios-document"></Icon>
		<span class="name">{{ data.name }}</span>
		<span class="option-btn" v-if="hoverId === data.id">
			<Icon
				v-if="!isParent"
				color="#fe834b"
				size="24"
				type="ios-create-outline"
				@click="edit(root, node, data)"
			></Icon>
			<Icon
				color="#fe834b"
				size="24"
				type="ios-add-circle-outline"
				@click="append(data)"
			></Icon>
			<Icon
				v-if="!isParent"
				color="#fe834b"
				size="24"
				type="ios-remove-circle-outline"
				@click="remove(root, node, data)"
			>
			</Icon>
		</span>
		<Modal
			v-model="addVisible"
			:mask-closable="false"
			:title="title"
			@on-cancel="onCancel"
		>
			<Input v-model="newVal" placeholder="请输入目录名称" />
			<div slot="footer">
				<Button type="text" @click="onCancel">取消</Button>
				<Button type="primary" @click="onOk" :loading="loading">确定</Button>
			</div>
		</Modal>
	</span>
</template>
<script>
import api from "@/api/setting/docConfig"

const { updateDocName, addDocName, deleteDocName } = api
export default {
	props: ["root", "node", "data", "moduleId", "draggable", "isParent"],
	name: "TreeItem",
	componentName: "TreeItem",
	data() {
		return {
			hoverId: "",
			newVal: "",
			formData: {},
			editObj: {},
			addVisible: false,
			loading: false,
			title: ""
		}
	},
	methods: {
		handleDragStart(event) {
			if (!this.draggable) return
			this.$emit("treeNodeDragStart", event, this)
		},

		handleDragOver(event) {
			if (!this.draggable) return
			this.$emit("treeNodeDragOver", event, this)
			event.preventDefault()
		},

		handleDrop(event) {
			if (!this.draggable) return
			this.$emit("treeNodeDragDrop", event, this)
			event.preventDefault()
		},

		handleDragEnd(event) {
			if (!this.draggable) return
			this.$emit("treeNodeDragEnd", event, this)
		},
		async onOk() {
			if (!this.newVal) {
				this.$Message.error("目录名称不能为空！")
				return false
			}
			this.loading = true
			const datas = this.formData.id
				? {
						id: this.moduleId,
						nodeId: this.formData.id,
						name: this.newVal
					}
				: {
						id: this.moduleId,
						name: this.newVal
					}
			if (this.isEdit) {
				const res = await updateDocName({
					id: this.moduleId,
					nodeId: this.formData.id,
					pid: this.formData.pid,
					name: this.newVal
				})
				const { root, node } = this.editObj
				const parentKey = root.find(el => el === node).parent
				const parent = root.find(el => el.nodeKey === parentKey).node
				if (res) {
					this.$Message.success("操作成功！")
					// console.log(parent);
					this.$emit("updateOk", parent)
					this.onCancel()
				}
				this.loading = false
			} else {
				const res = await addDocName(datas)
				if (res) {
					this.$Message.success("操作成功！")
					this.$emit("updateOk", this.formData)
					this.onCancel()
				}
				this.loading = false
			}
		},
		onCancel() {
			this.addVisible = false
		},
		// 新增
		append(data) {
			this.isEdit = false
			this.formData = data
			this.newVal = ""
			this.title = "新增文档目录"
			this.addVisible = true
		},
		// 编辑
		edit(root, node, data) {
			this.editObj = { root, node }
			this.isEdit = true
			this.formData = data
			this.newVal = data.name
			this.title = "修改文档目录"
			this.addVisible = true
		},
		// 删除
		remove(root, node, data) {
			// this.editObj = { root, node };
			this.formData = data
			this.$Modal.confirm({
				title: "确认删除该目录？",
				loading: true,
				onOk: async () => {
					const res = await deleteDocName({
						id: this.moduleId,
						nodeId: data.id,
						name: data.name
					})
					if (res) {
						const parentKey = root.find(el => el === node).parent
						const parent = root.find(el => el.nodeKey === parentKey).node
						this.$Message.success("操作成功！")
						this.$emit("updateOk", parent)
					}
					this.$Modal.remove()
					return true
				},
				onCancel: () => {}
			})
		},
		// 删除当前拖拽元素
		delete(root, node, data) {
			const parentKey = root.find(el => el === node).parent
			const parent = root.find(el => el.nodeKey === parentKey).node
			const index = parent.leaf.indexOf(data)
			parent.leaf.splice(index, 1)
		},
		// 在目标节点前添加元素
		insertBefore(root, node, child, ref) {
			const parentKey = root.find(el => el === node).parent
			const parent = root.find(el => el.nodeKey === parentKey).node
			if (ref) {
				const index = parent.leaf.indexOf(ref)
				parent.leaf.splice(index, 0, child.data)
			}
		},
		// 在目标节点后添加元素
		insertAfter(root, node, child, ref) {
			const parentKey = root.find(el => el === node).parent
			const parent = root.find(el => el.nodeKey === parentKey).node
			if (ref) {
				const index = parent.leaf.indexOf(ref)
				parent.leaf.splice(index + 1, 0, child.data)
			}
		},
		// 鼠标悬浮
		handleMouseOver(data) {
			this.hoverId = data.id
		}
	}
}
</script>
<style lang="less">
.custom-tree-item {
	display: inline-block;
	width: 100%;
	cursor: pointer;
	padding: 5px 0;
	.name {
		display: inline-block;
		white-space: normal;
		vertical-align: top;
		@cap: 120px;
		width: calc(~"100% - @{cap}");
		margin-left: 6px;
	}
	.option-btn {
		position: absolute;
		right: 0px;
		i {
			cursor: pointer;
			margin-left: 5px;
		}
	}
	&:hover {
		background: #f5f7fa;
	}
}
</style>
