<template>
	<Modal
		title="编辑文档目录"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<p style="color: #fda21e">
			提示：支持拖拽排序，但是仅支持同级目录下拖拽排序。
		</p>
		<Tree
			ref="docTree"
			class="doc-tree"
			:data="treeData"
			:render="renderContent"
			children-key="leaf"
		>
		</Tree>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
			<Button style="margin-right: 8px" type="primary" @click="onSave"
				>排序保存</Button
			>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/docConfig"
import TreeItem from "./TreeItem.vue"

const { getDocConfigTreeById, sortSaveDocName } = api
export default {
	name: "docConfig.View",
	props: ["viewId", "viewName", "visible"],
	components: {
		TreeItem
	},

	data() {
		return {
			loading: false,
			treeData: [],
			buttonProps: {
				type: "default",
				size: "small"
			},
			dragState: {
				showDropIndicator: false,
				draggingNode: null,
				dropNode: null,
				allowDrop: true
			},
			draggable: true
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		// 新增，修改、删除展开
		recursiveData(treeData, expandData) {
			return treeData.map(tree => {
				if (tree.leaf && tree.leaf.length) {
					if (expandData.id && expandData.id.indexOf(tree.id) > -1) {
						this.$set(tree, "expand", true)
					}
					this.recursiveData(tree.leaf, expandData)
				}
				return tree
			})
		},
		async getTreeList(expandData) {
			this.loading = true
			const res = await getDocConfigTreeById(this.viewId)
			if (res) {
				const treeData = [
					{
						id: null,
						name: this.viewName,
						leaf: res.data,
						expand: true,
						render: (h, { root, node, data }) =>
							h(TreeItem, {
								props: {
									root,
									node,
									data,
									moduleId: this.viewId,
									draggable: false,
									isParent: true
								},
								on: {
									updateOk: () => {
										this.getTreeList()
									}
								}
							})
					}
				]
				if (expandData && expandData.id) {
					this.recursiveData(treeData, expandData)
				}
				this.treeData = treeData
			}
			this.loading = false
		},
		renderContent(h, { root, node, data }) {
			return h(TreeItem, {
				props: {
					root,
					node,
					data,
					moduleId: this.viewId,
					draggable: true
				},
				on: {
					updateOk: expandData => {
						this.getTreeList(expandData)
					},
					treeNodeDragStart: this.treeNodeDragStart,
					treeNodeDragOver: this.treeNodeDragOver,
					treeNodeDragEnd: this.treeNodeDragEnd
				}
			})
		},
		treeNodeDragStart(event, treeNode) {
			if (!this.draggable) {
				event.preventDefault()
				return false
			}
			this.$set(event.dataTransfer, "effectAllowed", "move")
			this.$set(this.dragState, "draggingNode", treeNode)
			this.$emit("node-drag-start", treeNode.node, event)
			return true
		},
		treeNodeDragOver(event, targetNode) {
			const { dragState } = this
			const { draggingNode } = dragState
			const dropNode = targetNode
			const oldDropNode = dragState.dropNode
			if (!draggingNode || !dropNode) return
			const targetPosition = dropNode.$el.getBoundingClientRect()
			const prevPercent = 0.5
			const nextPercent = 0.5
			const distance = event.clientY - targetPosition.top
			let dropType = ""
			if (distance < targetPosition.height * prevPercent) {
				dropType = "before"
			} else if (distance > targetPosition.height * nextPercent) {
				dropType = "after"
			} else {
				dropType = "none"
			}
			Object.assign(this.dragState, {
				...this.dragState,
				dropType,
				dropNode
			})
		},
		treeNodeDragEnd(event, targetNode) {
			const { draggingNode, dropType, dropNode } = this.dragState
			if (draggingNode && dropNode) {
				const draggingNodeCopy = draggingNode
				if (dropType !== "none") {
					const { root, node, data } = draggingNode
					draggingNode.delete(root, node, data)
				}
				if (dropType === "before") {
					const { root, node, data } = dropNode
					dropNode.insertBefore(root, node, draggingNodeCopy, data)
				} else if (dropType === "after") {
					const { root, node, data } = dropNode
					dropNode.insertAfter(root, node, draggingNodeCopy, data)
				}
				if (dropType !== "none") {
					this.$set(this.dragState, "draggingNode", draggingNodeCopy)
				}
			}
			this.dragState.showDropIndicator = false
			this.dragState.allowDrop = true
			event.preventDefault()
			event.dataTransfer.dropEffect = "move"
		},
		async onSave() {
			this.loading = true
			const res = await sortSaveDocName({
				id: this.viewId,
				nodes: this.treeData[0].leaf
			})
			if (res) {
				this.$Message.success("操作成功！")
				this.onCancel()
			}
			this.loading = false
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				if (this.viewId) {
					this.getTreeList()
				}
			}
		}
	},
	mounted() {
		// this.getTreeList();
	}
}
</script>

<style lang="less">
.doc-tree.ivu-tree ul li {
	position: relative !important;
	.ivu-tree-title {
		display: inline;
	}
}
</style>
