import request from "@/utils/request"

const moduleName = "/common/doc-configs"

export default {
	getConfigList: data =>
		request(`${moduleName}/full-query`, {
			method: "get",
			params: data
		}),
	getDocConfigTreeById: id =>
		request(`/common/doc-config/${id}/tree`, {
			method: "get"
		}),
	// 导入/配置项目初始化文档库
	uploadDocConfig: body =>
		request("/common/doc-config", {
			method: "post",
			body
		}),
	// 删除模板
	deleteDocConfig: body =>
		request(`/common/doc-config/${body.id}`, {
			method: "delete"
		}),
	// 新增模板目录名称
	addDocName: data =>
		request(`/common/doc-config/${data.id}/node`, {
			method: "post",
			body: data
		}),
	// 修改模板目录名称
	updateDocName: data =>
		request(`/common/doc-config/${data.id}/node`, {
			method: "put",
			body: data
		}),
	// 删除模板目录名称
	deleteDocName: data =>
		request(`/common/doc-config/${data.id}/node`, {
			method: "delete",
			body: data
		}),
	// 模板目录排序保存
	sortSaveDocName: data =>
		request(`/common/doc-config/${data.id}/sort-node`, {
			method: "post",
			body: data
		})
}
